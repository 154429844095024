import React from 'react';
import type { FunctionComponent } from 'react';
import type { AppPropsWithLayout } from '@/types/AppWithLayout';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import theme from '@/lib/style/caire-theme';
import AuthenticatedApolloProvider from '@/components/AuthenticatedApolloProvider';
import CaireStyle from '@/components/CaireStyle';
import RealUserMetrics from '@/components/DataDog/RealUserMetrics';
import TwilioProvider from '@/contexts/Twilio';
import { FeatureFlagProvider } from '@/contexts/FeatureFlag';
import ComponentHierarchy, { component } from '@/components/ComponentHierarchy';
import { ProgramSwitchProvider } from '@/contexts/ProgramSwitch';
import { attachLogging as possiblyAttachA11yLogging } from '@/lib/a11y/axe-react-console';
import { IdleSessionTimeout } from '@/components/IdleSessionTimeout/IdleSessionTimeout';
import { CognitoAuthProvider, CognitoActionProvider } from '@/contexts/Cognito';
import {
  TenantConfigLoader,
  TenantConfigProvider,
} from '@/contexts/TenantConfig';
import { UserPerspective } from '@/types/userPerspective';

/* N.B. NextJS requires that global styles be imported in _app.tsx, and cannot be
   refactored into a webapp-shared component. */
import '@shared-module-asset/chartist/dist/index.css';

// Future: Consider only importing the styles for components we use to reduce bundle size.
import '@shared-module-asset/@mantine/core/styles.css';
import '@shared-module-asset/@mantine/carousel/styles.css';
import '@shared-module-asset/@mantine/dates/styles.css';
import '@shared-module-asset/@mantine/notifications/styles.css';

// AWS Amplify UI theme for Auth components (https://ui.docs.amplify.aws/react/getting-started/installation)
import '@aws-amplify/ui-react/styles.css';

import '@/style/reset.css';
import '@/style/theme/index.css';
import '@/style/mantine-overrides/index.css';
// TODO CRE-2044 -- refactor to component module styles
import '@/style/survey-js.css';
// TODO CRE-2043 -- refactor to component module styles
import '@/style/full-calendar.css';

const App: FunctionComponent<AppPropsWithLayout> = ({
  Component,
  pageProps,
}) => {
  const apiUrl = process.env.NEXT_PUBLIC_GRAPH_API_URL;

  const datadogAppId = process.env.NEXT_PUBLIC_DATADOG_APP_ID;
  const datadogClientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
  const datadogEnvironment = process.env.NEXT_PUBLIC_ENVIRONMENT;
  const datadogEnabled =
    datadogAppId && datadogClientToken && datadogEnvironment;

  if (!apiUrl) {
    throw new Error('GraphQL gateway environment variable not set');
  }

  // Extract PageWithLayout extensions to pass into provider hierarchy:
  const getLayout = Component.getLayout ?? ((page) => page);
  const { toastPosition = 'bottom-right' } = Component.appConfig ?? {};

  return (
    <>
      {datadogEnabled && (
        <RealUserMetrics
          applicationId={datadogAppId}
          clientToken={datadogClientToken}
          environment={datadogEnvironment}
          tracingUrls={[apiUrl]}
          serviceName="team-portal"
        />
      )}

      <ComponentHierarchy
        items={[
          component(CognitoAuthProvider),
          component(TenantConfigProvider),
          component(ProgramSwitchProvider),
          component(CaireStyle, { theme: 'caire' }),
          component(MantineProvider, {
            theme: theme,
            // We don't include Mantine's global styles, as we don't use Mantine's hiddenFrom/lightHidden tools
            withGlobalClasses: false,
            // We declare Mantine CSS variables in mantine-vars.css, rather than inline injection
            withCssVariables: false,
          }),
          component(AuthenticatedApolloProvider),
          component(CognitoActionProvider, {
            loginUrl: '/',
            afterLoginUrl: '/auth/bypass',
          }),
          component(TenantConfigLoader, {
            perspective: UserPerspective.Staff,
          }),
          component(FeatureFlagProvider),
          component(IdleSessionTimeout),
          component(TwilioProvider, { userPerspective: UserPerspective.Staff }),
          component(ModalsProvider, {
            modalProps: {
              withinPortal: false,
            },
          }),
        ]}>
        <Notifications position={toastPosition} />
        {getLayout(<Component {...pageProps} />)}
      </ComponentHierarchy>
    </>
  );
};

possiblyAttachA11yLogging(React);

export default App;
